import React, { useState } from 'react';
import cx from 'clsx';
import styles from './ExpandList.module.scss';
import useAccessibleClick from '../../hooks/useAccessibleClick';

export interface ExpandListProps {
  min: number;
  list: string[];
  cssClass?: string;
}

const ExpandList = ({ min, list, cssClass }: ExpandListProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const handleExpand = useAccessibleClick(() => setShowAll(!showAll));

  return (
    <div className={cx(cssClass)} data-testid="expoand-list-comp">
      {!showAll && list.length > min ? (
        <>
          {list.slice(0, min).join(',')}...
          <span className={styles.enxpandLink} {...handleExpand}>{`(${list.length - min})`}</span>
        </>
      ) : (
        list.join(',')
      )}
    </div>
  );
};

export default ExpandList;
