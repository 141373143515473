import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, ConfirmModal, DateComponent, Modal, Upload } from 'vapi-ui-common';
import IconTextButton from '../../../../../../components/IconTextButton';
import { TableRow } from '../../../../../../components/Table';
import { VD_PUBLISH_TYPE } from '../../../../../../constants/vehicleData/VDConstants';
import { BRAND_TDPR, BRAND_TOYOTA, Language } from '../../../../../../models/user.model';
import {
  DashboardDetailYear,
  VDInProgressToPublish,
  VDStatus,
  VehicleTeam,
} from '../../../../../../models/vehicleData.model';
import { getParamsForVersionInfo } from '../../../../../../utils/vehicleDataUtils';
import RemoveDraftModal from '../RemoveDraftModal';
import StatusIndicator from '../StatusIndicator';
import UploadDraftModal from '../UploadDraftModal';
import ConfirmPublishModal from './ConfirmPublishModal/ConfirmPublishModal';
import PublishEFCModal from './PublishEFCModal';
import styles from './vehicleTableRow.module.scss';
import useAccessibleClick from '../../../../../../hooks/useAccessibleClick';

const TDLink = ({
  children,
  className,
  onClick,
}: {
  className: string;
  onClick?: () => void;
  children?: ReactNode;
}) => {
  const handleClick = useAccessibleClick(() => onClick?.());

  return (
    <td className={className} {...handleClick}>
      {children}
    </td>
  );
};

interface VehicleTableRowProps extends RouteComponentProps {
  item: DashboardDetailYear;
  seriesId: string;
  seriesName: string;
  team: VehicleTeam;
  teamLanguages: Language[];
  allowPreviewPublishSeries: boolean;
  useNewStatus: boolean;
  onDeleteItem: (seriesId: string, modelYear: number) => void;
  onCreateDraft: (seriesId: string, modelYear: number) => void;
  canEdit?: boolean;
  onPreview?: (seriesId: string, modelYear: number) => void;
  onPublish?: (seriesId: string, modelYear: number) => void;
  onPreviewWithInProgressData?: (
    seriesId: string,
    modelYear: number
  ) => (inProgressToPublish: VDInProgressToPublish) => void;
  onPublishWithInProgressData?: (
    seriesId: string,
    modelYear: number
  ) => (inProgressToPublish: VDInProgressToPublish) => void;
  isSpanish: boolean;
  showModelDetails: boolean;
  onDownload?: (item: DashboardDetailYear, seriesId: string, seriesName: string) => void;
  onFileUpload?: (seriesId: string, modelYear: number, resultJSON?: object) => void;
  canEditSpanish?: boolean;
  hasSpanishDraft?: boolean;
  brand: string;
}

const VehicleTableRow = observer(
  ({
    item,
    seriesId,
    seriesName,
    team,
    teamLanguages,
    allowPreviewPublishSeries,
    useNewStatus,
    history,
    onDeleteItem,
    onCreateDraft,
    canEdit,
    onPreview,
    onPublish,
    onPreviewWithInProgressData,
    onPublishWithInProgressData,
    isSpanish,
    onDownload,
    onFileUpload,
    canEditSpanish,
    showModelDetails,
    brand,
    hasSpanishDraft = false,
  }: VehicleTableRowProps) => {
    const [showIcons, setShowIcons] = useState(true);
    const [removeDraft, setRemoveDraft] = useState(false);
    const [publishATModal, setPublishATModal] = useState(false);
    const [previewATModal, setPreviewATModal] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [previewEFCModal, setPreviewEFCModal] = useState(false);
    const [publishEFCModal, setPublishEFCModal] = useState(false);
    const [uploadJSON, setUploadJSON] = useState<object | undefined>(undefined);

    const { versionInfoParams, versionInfo } = getParamsForVersionInfo(team, teamLanguages, item);
    const draftLink = `/vehicleData/draft/${team}/${seriesId}/${item.year}/${versionInfoParams}`;
    const publishLink = `/vehicleData/published/${team}/${seriesId}/${item.year}/${versionInfoParams}`;
    const version = isSpanish ? item.spanishVersion : item.version;

    const showPublish =
      (allowPreviewPublishSeries &&
        item.vdStatus &&
        (item.vdStatus.currentStatus === VDStatus.APPROVED ||
          (brand === BRAND_TDPR && item.vdStatus.currentStatus === VDStatus.CERTIFIED))) ||
      (isSpanish && canEditSpanish && !item.isDraft && !!version);

    const handlerAllowLinkRedirect = () => {
      if (!isSpanish || process.env.REACT_APP_MULTI_LANG === 'true') {
        item.isDraft ? history.push(draftLink) : history.push(publishLink);
      }
    };

    const handleDownload = useAccessibleClick(() => {
      onDownload?.(item, seriesId, seriesName);
    });

    return (
      <TableRow
        zebra
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(true)}
      >
        <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
          <span className={styles.content}>{item.year}</span>
        </TDLink>

        <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
          <span className={styles.content}>
            {item.publishDownstreamDate ? (
              <span>
                <DateComponent format="MM/DD/YY">{item.publishDownstreamDate}</DateComponent>
                {!isSpanish && <span>- V {item.publishDownstreamVersion} </span>}
              </span>
            ) : item.datePublished ? (
              <DateComponent format="MM/DD/YY">{item.datePublished}</DateComponent>
            ) : (
              '——'
            )}
          </span>
        </TDLink>

        <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
          {isSpanish ? (
            <>
              <div>EN: V{item.sourceVersion}</div>
              <div>ES: V{item.spanishVersion}</div>
            </>
          ) : (
            <span className={styles.content}>V{item.version}</span>
          )}
        </TDLink>

        {isSpanish ? (
          <>
            <TDLink className={styles.tableText}>
              {!item.isDraft && canEditSpanish && onFileUpload && (
                <Upload
                  onUpload={(_, resultJSON) => {
                    if (hasSpanishDraft) {
                      setUploadJSON(resultJSON);
                      setOpenUploadModal(true);
                    } else {
                      onFileUpload(seriesId, item.year, resultJSON);
                    }
                  }}
                  uploadLabel="Upload"
                  id={`${item.year}#${seriesId}#${version}`}
                />
              )}
            </TDLink>
            <TDLink className={styles.tableText}>
              {!item.isDraft && (
                <div {...handleDownload}>
                  <span className={styles.actionLabel}>
                    Download EN: V{item.englishVersion}
                    <FontAwesomeIcon className={styles.upDown} icon={faArrowDown} />
                  </span>
                </div>
              )}
            </TDLink>
          </>
        ) : (
          <>
            <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
              {item.notes}
            </TDLink>
            <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
              {useNewStatus && <StatusIndicator item={item} />}
            </TDLink>
          </>
        )}
        {isSpanish && process.env.REACT_APP_MULTI_LANG === 'true' && (
          <>
            <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
              {item.notes}
            </TDLink>
            <TDLink className={styles.tableText} onClick={handlerAllowLinkRedirect}>
              {useNewStatus && <StatusIndicator item={item} isSpanish />}
            </TDLink>
          </>
        )}

        <TDLink className={cx(styles.tableText, styles.actionBtns)}>
          {
            <span className={cx(styles.actionBtnsWrapper, !showIcons && styles.hideIcon)}>
              {canEdit && showPublish && (
                <PreviewButton
                  allowPreviewPublishSeries={allowPreviewPublishSeries}
                  isSpanish={isSpanish && !!canEditSpanish}
                  onClick={() => setPreviewATModal(true)}
                  item={item}
                  disabled={
                    typeof item.previewDownstreamVersion === 'number' &&
                    item.previewDownstreamVersion === version
                  }
                  brand={brand}
                />
              )}
              {canEdit && showPublish && (
                <Button
                  variant="secondary"
                  onClick={() => setPublishATModal(true)}
                  disabled={
                    typeof item.publishDownstreamVersion === 'number' &&
                    item.publishDownstreamVersion === version
                  }
                >
                  Publish
                </Button>
              )}
              {canEdit &&
                !item.isDraft &&
                (!isSpanish ||
                  (process.env.REACT_APP_MULTI_LANG === 'true' && !!item.spanishVersion)) && (
                  <IconTextButton
                    icon="edit"
                    text=""
                    className={styles.addModelYearCta}
                    onClick={() => onCreateDraft(seriesId, item.year)}
                  />
                )}

              {canEdit &&
                item.isDraft &&
                !item.isSubmitted &&
                (!isSpanish || process.env.REACT_APP_MULTI_LANG === 'true') && (
                  <IconTextButton
                    icon="remove"
                    text=""
                    className={styles.removeCta}
                    onClick={() => setRemoveDraft(true)}
                  />
                )}
            </span>
          }
          <Modal open={removeDraft} onClose={() => setRemoveDraft(false)}>
            <RemoveDraftModal
              close={() => setRemoveDraft(false)}
              onSubmit={() => onDeleteItem(seriesId, item.year)}
            />
          </Modal>
          <Modal open={openUploadModal} onClose={() => setOpenUploadModal(false)}>
            <UploadDraftModal
              close={() => setOpenUploadModal(false)}
              onSubmit={() => onFileUpload && onFileUpload(seriesId, item.year, uploadJSON)}
            />
          </Modal>
          <ConfirmModal
            headerText="Preview Series"
            bodyText={`Are you sure you want to preview the ${item.year} ${seriesName}?`}
            confirmButtonText={'Preview'}
            open={previewATModal}
            onClose={() => setPreviewATModal(false)}
            onConfirm={() => {
              setPreviewATModal(false);
              if (
                process.env.REACT_APP_LIMITED_DATA === 'true' &&
                brand === BRAND_TOYOTA &&
                !isSpanish
              ) {
                setPreviewEFCModal(true);
              } else {
                onPreview && onPreview(seriesId, item.year);
              }
            }}
          />
          <ConfirmPublishModal
            headerText={`Publish V${version}`}
            version={version}
            versionInfo={versionInfo}
            confirmButtonText={'Certify & Publish'}
            open={publishATModal}
            onClose={() => setPublishATModal(false)}
            onConfirm={() => {
              setPublishATModal(false);
              if (
                process.env.REACT_APP_LIMITED_DATA === 'true' &&
                brand === BRAND_TOYOTA &&
                !isSpanish
              ) {
                setPublishEFCModal(true);
              } else {
                onPublish && onPublish(seriesId, item.year);
              }
            }}
            team={team}
            seriesId={seriesId}
            year={item.year.toString()}
            isDraft={item.isDraft}
            showModelDetails={showModelDetails}
          />
          <PublishEFCModal
            open={previewEFCModal}
            onConfirm={() => onPreview && onPreview(seriesId, item.year)}
            onConfirmWithInProgressData={
              onPreviewWithInProgressData
                ? onPreviewWithInProgressData(seriesId, item.year)
                : undefined
            }
            onClose={() => setPreviewEFCModal(false)}
            seriesId={seriesId}
            year={item.year.toString()}
            versionInfo={versionInfo}
            publishType={VD_PUBLISH_TYPE.PREVIEW}
          />
          <PublishEFCModal
            open={publishEFCModal}
            onConfirm={() => onPublish && onPublish(seriesId, item.year)}
            onConfirmWithInProgressData={
              onPublishWithInProgressData
                ? onPublishWithInProgressData(seriesId, item.year)
                : undefined
            }
            onClose={() => setPublishEFCModal(false)}
            seriesId={seriesId}
            year={item.year.toString()}
            versionInfo={versionInfo}
          />
        </TDLink>
      </TableRow>
    );
  }
);

const PreviewButton = ({
  allowPreviewPublishSeries,
  isSpanish,
  item,
  disabled,
  onClick,
  brand,
}: {
  allowPreviewPublishSeries: boolean;
  isSpanish: boolean;
  item: DashboardDetailYear;
  disabled?: boolean;
  onClick?: () => void;
  brand: string;
}) => {
  return (allowPreviewPublishSeries &&
    item.vdStatus &&
    (item.vdStatus.currentStatus === VDStatus.APPROVED ||
      item.vdStatus.currentStatus === VDStatus.SUBMITTED ||
      (brand === BRAND_TDPR && item.vdStatus.currentStatus === VDStatus.CERTIFIED))) ||
    isSpanish ? (
    <Button variant="outline" onClick={onClick} disabled={disabled}>
      Preview
    </Button>
  ) : (
    <></>
  );
};

export default withRouter(VehicleTableRow);
