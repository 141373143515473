import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { removeNulls } from '../utils';
import { ChangeLogItem } from './changeLog.model';
import { GroupItem } from './colorsLexus.model';
import { KeyValueType } from './common.model';
import { RefItem } from './refItem.model';
import {
  ReviewChangeBaseItem,
  ReviewChangeMap,
  ReviewChangeRequest,
  ReviewChangeResponse,
  ReviewChangeTypeMap,
} from './review.model';
import {
  CreateExtColorToyotaInput,
  CreateIntColorToyotaInput,
  UpdateExtColorToyotaInput,
  UpdateIntColorToyotaInput,
} from '../gql/generated';

export interface IColorItem {
  uid: string;
  interiorApplicability: InteriorApplicability;
}

export class ColorItem implements IColorItem {
  uid = '';
  id = '';
  revId = '';
  @observable name = '';
  @observable code = '';
  @observable hexCode = '';
  @observable colorApplicability = [] as ColorApplicability[];
  @observable isExtraCost = '';
  @observable isInProgress = false;
  @observable notes = '';
  @observable rejectNotes = '';
  @observable interiorApplicability = {} as InteriorApplicability;
  @observable interiorColors: InteriorColorItem[] = [];
  @observable sortOrder: string | number = 0;
  @observable changedAttributes: string[] = [];
  @observable fromTMNA: boolean = false;
  @observable fieldStatus?: ExteriorColorFieldStatus = {} as ExteriorColorFieldStatus;

  constructor(color?: ExteriorColorResponse) {
    this.uid = uuidv4();
    if (color) {
      Object.assign(this, removeNulls(color));
      this.isExtraCost = typeof color.isExtraCost === 'boolean' ? '' : color.isExtraCost;
    }
  }

  @computed get createPayload() {
    const payload: CreateExtColorToyotaInput = {
      id: this.id,
      name: convertToRichTextObject(this.name).text.trim(),
      code: this.code,
      hexCode: this.hexCode,
      colorApplicability: this.colorApplicability,
      isExtraCost: this.isExtraCost,
      isInProgress: this.isInProgress,
      notes: this.notes,
    };

    return payload;
  }

  @computed get updatePayload() {
    const payload: UpdateExtColorToyotaInput = {
      id: this.id,
      revId: this.revId,
      name: convertToRichTextObject(this.name).text.trim(),
      code: this.code,
      hexCode: this.hexCode,
      colorApplicability: this.colorApplicability,
      isExtraCost: this.isExtraCost,
      isInProgress: this.isInProgress,
      notes: this.notes,
    };

    return payload;
  }

  isValid = (isHexCodeRequired: boolean): boolean => {
    const hasValidCode = () => !!this.code,
      hasValidName = () => !!this.name,
      hasValidHexCode = () => !!this.hexCode,
      validations: Array<() => boolean> = [hasValidCode, hasValidName];

    if (isHexCodeRequired) {
      validations.push(hasValidHexCode);
    }

    return validations.map(test => test()).some(result => !result);
  };
}

export interface ExteriorColorFieldStatus {
  id: string;
  status: number;
}

export type ExteriorColorFieldStatusRequest = ExteriorColorFieldStatus;

export interface ExteriorColorResponse {
  id: string;
  revId: string;
  name: string;
  code: string;
  hexCode: string;
  colorApplicability: ColorApplicability[];
  isExtraCost: string;
  isInProgress: boolean;
  notes: string | undefined;
  changedAttributes?: string[];
  fromTMNA?: boolean;
  fieldStatus?: ExteriorColorFieldStatus;
}

export class ColorGradeItem {
  uid = '';
  @observable grade: RefItem;
  @observable interiorItems: InteriorColorResponse[] = [];

  constructor(grade: RefItem) {
    this.uid = uuidv4();
    this.grade = grade;
  }
}

export class ColorGradeLangItem {
  uid = '';
  @observable grade: RefItem;
  @observable interiorItems: InteriorColorLangMap[] = [];

  constructor(grade: RefItem) {
    this.uid = uuidv4();
    this.grade = grade;
  }
}

export type ExteriorColorRequest = Omit<ExteriorColorResponse, 'fieldStatus'>;

export type InteriorApplicability = {
  [grade: string]: InteriorItem[];
};

export type ColorApplicability = {
  interiorColorId: string;
  grade: string; // grade id
};

// map of gradeIds to applicable interior color ids
export type ColorApplicabilityReviewMap = {
  [gradeId: string]: { [interiorColorId: string]: true };
};

export class InteriorItem {
  @observable grade: RefItem;
  @observable checked = false;
  @observable interiorItem = {} as InteriorColorResponse;

  constructor(grade: RefItem, interiorItem: InteriorColorResponse, checked: boolean) {
    this.grade = grade;
    this.interiorItem = interiorItem;
    this.checked = checked;
  }
}

export type IntColorModelApplicability = {
  [gradeId: string]: { [modelId: string]: boolean };
};

export class InteriorColorItem {
  uid = '';
  id = '';
  revId = '';
  @observable name = '';
  @observable code = '';
  @observable interiorTypeId = '';
  @observable rejectNotes = '';
  @observable isExtraCost = '';
  @observable modelApplicability = {} as IntColorModelApplicability;
  @observable changedAttributes: string[] = [];
  @observable fromTMNA: boolean = false;
  @observable fieldStatus?: InteriorColorFieldStatus = {
    modelApplicability: {},
  } as InteriorColorFieldStatus;

  constructor(intColor?: InteriorColorResponse) {
    this.uid = uuidv4();
    if (intColor) {
      this.name = intColor.name;
      this.id = intColor.id;
      this.code = intColor.code;
      this.revId = intColor.revId;
      this.rejectNotes = intColor.rejectNotes;
      this.isExtraCost = intColor.isExtraCost || '';
      this.modelApplicability = intColor.modelApplicability;
      this.fieldStatus = intColor.fieldStatus;

      if (intColor.changedAttributes) {
        this.changedAttributes = intColor.changedAttributes;
      }

      if (intColor.fromTMNA) {
        this.fromTMNA = intColor.fromTMNA;
      }
    }
  }

  @computed get createPayload() {
    const payload: CreateIntColorToyotaInput = {
      id: this.id,
      name: convertToRichTextObject(this.name).text.trim(),
      code: this.code,
      isExtraCost: this.isExtraCost,
      modelApplicability: this.modelApplicability,
    };

    return payload;
  }

  @computed get updatePayload() {
    const payload: UpdateIntColorToyotaInput = {
      id: this.id,
      revId: this.revId,
      name: convertToRichTextObject(this.name).text.trim(),
      code: this.code,
      isExtraCost: this.isExtraCost,
      modelApplicability: this.modelApplicability,
    };

    return payload;
  }

  @computed get isValid() {
    return this.name !== '';
  }
}

export interface InteriorColorFieldStatus {
  id: string;
  modelApplicability: KeyValueType<KeyValueType<LIMITED_DATA_STATUS>>;
}

export type InteriorColorFieldStatusRequest = InteriorColorFieldStatus;

export interface InteriorColorResponse {
  id: string;
  name: string;
  revId: string;
  code: string;
  rejectNotes: string;
  isExtraCost: string;
  modelApplicability: IntColorModelApplicability;
  changedAttributes?: string[];
  fromTMNA?: boolean;
  fieldStatus?: InteriorColorFieldStatus;
}

export type InteriorColorRequest = Omit<InteriorColorResponse, 'fieldStatus'>;

export type InteriorColorTypeResponse = {
  // toyota field
  intColorTypes: KeyValueType;

  // lexus fields
  groups: GroupItem;
  interiorTypes: KeyValueType;
  materials: KeyValueType;
  ornaments: KeyValueType;
};

export interface GradesChecklist {
  name: string;
  selected: boolean;
}

/**
 * Options Review Item
 */
export class ColorsReviewItem implements IColorItem {
  @observable uid = uuidv4();
  @observable test = '';
  @observable id = '';
  @observable revId = '';
  @observable description = '';
  @observable hexCode = '';
  @observable isExtraCost = '';
  @observable code = '';
  @observable interiorTypeId = '';
  @observable name = '';
  @observable exterior = '';
  @observable interior = '';
  @observable interiorApplicability = {} as InteriorApplicability;
  @observable changes = new ChangeLogItem();
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable changeTypeId = '';
  @observable isAccepted = true;
  @observable isApplied = true;
  @observable isNewChange = false;
  @observable rejectNotes = '';
  @observable notes = '';
  @observable isInProgress = false;
  @observable isHighlighted = false;
  @observable interiorType = '';
  @observable rowHeight = undefined as number | undefined;
  @observable modelApplicability = {} as IntColorModelApplicability;

  constructor(response?: ColorsReviewResponse, change?: ReviewChangeResponse) {
    this.uid = uuidv4();
    if (response) {
      const { changes, otherChanges, ...rest } = response;
      Object.assign(this, removeNulls(rest));
    }

    if (change) {
      const { after, before, changeTypeId, changeType, ...rest } = change;

      Object.assign(this, removeNulls(rest));
      Object.assign(this.changes, removeNulls({ after, before, changeTypeId, changeType }));
    }

    if (response?.otherChanges) {
      this.otherChanges = Object.entries(response.otherChanges).map(
        ([changeTypeId, otherChange]) => {
          return new ReviewChangeBaseItem(otherChange, changeTypeId);
        }
      );
    }
  }

  getPayload = (): ReviewChangeRequest => {
    return {
      id: this.id,
      revId: this.revId,
      isAccepted: this.isAccepted,
      isApplied: this.isApplied,
      changeTypeId: this.changeTypeId,
      rejectNotes: this.rejectNotes,
    };
  };

  isValid = (): boolean => {
    return this.isAccepted || !!this.rejectNotes;
  };
}

export interface ColorsReviewResponse {
  isHighlighted: string;
  name: string;
  hexCode: string;
  isExtraCost: string;
  code: string;
  interiorTypeId: string;
  notes: string;
  link: string;
  revId: string;
  colorApplicability: ColorApplicability[];
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  isNewChange: boolean;
  id: string;
  isInProgress: boolean;
  interiorType: string;
  rejectNotes: string;
  modelApplicability: IntColorModelApplicability;
}

export interface ExteriorColorsReviewMap {
  [id: string]: ExteriorColorsChangeTypeMap;
}

export interface ExteriorColorsChangeTypeMap extends ReviewChangeTypeMap {
  hexCode: string;
  name: ReviewChangeMap<string>;
  code: ReviewChangeMap<string>;
  isExtraCost: ReviewChangeMap<string>;
  colorApplicability: ReviewChangeMap<ColorApplicabilityReviewMap>;
}

export type ExteriorColorReviewType =
  | 'name'
  | 'code'
  | 'isExtraCost'
  | 'colorApplicability'
  | 'added'
  | 'deleted';

export interface InteriorColorsReviewMap {
  [id: string]: InteriorColorsChangeTypeMap;
}

export interface InteriorColorsChangeTypeMap extends ReviewChangeTypeMap {
  isExtraCost: string;
  name: ReviewChangeMap<string>;
  code: ReviewChangeMap<string>;
  // isExtraCost: ReviewChangeMap<string>; // TODO: figure out if reviewable field
  modelApplicability: ReviewChangeMap<KeyValueType<boolean>>;
}

export type InteriorColorReviewType =
  | 'name'
  | 'code'
  // | 'isExtraCost' // TODO: see if isExtraCost should be included
  | 'modelApplicability'
  | 'added'
  | 'deleted';

export type InteriorColorType = 'groups' | 'materials' | 'ornaments' | 'interiorTypes';

export enum COLORS_TAB {
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
}

export interface IntColorModelCodes {
  id: string;
  name: string;
  selected: boolean;
  hide: boolean;
}

export interface IntColorGradesList {
  id: string;
  name: string;
  selected: boolean;
  items: IntColorModelCodes[];
}

export interface InteriorColorMap {
  colors: {
    [colorId: string]: InteriorColorLangMap;
  };
}

export interface InteriorColorLangMap {
  langs: {
    [lang: string]: InteriorColorResponse;
  };
  defaultData: InteriorColorResponse;
}

export interface InteriorColorLangItemMap {
  langs: {
    [lang: string]: InteriorColorItem;
  };
}

export interface ExteriorColorMap {
  colors: {
    [colorId: string]: ExteriorColorLangMap;
  };
}

export interface ExteriorColorLangMap {
  langs: {
    [lang: string]: ExteriorColorResponse;
  };
  defaultData: ExteriorColorResponse;
}

export interface ColorItemMap {
  colors: {
    [colorId: string]: ColorItemLangMap;
  };
  order: string[];
}

export interface ColorItemLangMap {
  langs: {
    [lang: string]: ColorItem;
  };
}
