import React from 'react';
import Input from '../../../../../../../components/Input';
import styles from '../../../modelsModal.module.scss';

const HiddenField = () => {
  return (
    <div>
      <div className={styles.hide}>
        <label>
          hidden
          <Input disabled />
        </label>
      </div>
    </div>
  );
};

export default HiddenField;
