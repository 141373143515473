import { useCallback } from 'react';

export interface UseAccessibleClickOptions {
  role?: string;
  disabled?: boolean;
}

function useAccessibleClick(
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void,
  { role, disabled }: UseAccessibleClickOptions = { role: 'button', disabled: false }
) {
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (!disabled && (event.key === 'Enter' || event.key === ' ')) {
        event.preventDefault(); // Prevent the default spacebar scrolling behavior
        onClick?.(event);
      }
    },
    [onClick, disabled]
  );

  return {
    role,
    tabIndex: disabled ? -1 : 0,
    onClick: disabled ? undefined : onClick,
    onKeyDown: disabled ? undefined : handleKeyPress,
    'aria-disabled': disabled || undefined,
  };
}

export default useAccessibleClick;
