import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumnList,
  FilterTabColumns,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../../components/Checkbox';
import Input from '../../../../../../../components/Input';
import useChecklist, { ChecklistItem } from '../../../../../../../hooks/useChecklist';
import useStores from '../../../../../../../hooks/useStores';
import { InteriorColorType } from '../../../../../../../models/colors.model';
import { InteriorCategory, InteriorMaterial } from '../../../../../../../models/colorsLexus.model';
import { IDValueType } from '../../../../../../../models/common.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import { groupsWithModelCodeXform } from '../../../../../../../utils/colorLexusUtils';
import { reassignModelCodes } from '../../../../../../../utils/colorUtils';
import ColorsInteriorDropdown from './ColorsInteriorDropdown';
import styles from './colorsLexusInteriorModal.module.scss';

interface ColorsLexusInteriorModalProps {
  closeModal(): void;
  materialItem?: InteriorMaterial;
  categoryItem?: InteriorCategory;
  saveInteriorColorType?(
    value: IDValueType,
    interiorColorType: InteriorColorType,
    isNew?: boolean
  ): void;
  saveInteriorCategory?(interiorCategory: InteriorCategory): void;
  deleteInteriorCategory?(interiorCategory: InteriorCategory): void;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
}

const ColorsLexusInteriorModal = (props: ColorsLexusInteriorModalProps) => {
  const {
    closeModal,
    saveInteriorColorType = () => void 0,
    saveInteriorCategory = () => void 0,
    deleteInteriorCategory = () => void 0,
    vehicleModels,
  } = props;

  const {
    colorsLexusStore: { interiorColorTypes },
  } = useStores();

  const [isStep1, setIsStep1] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [materialItem] = useState(props.materialItem ? props.materialItem : new InteriorMaterial());
  const [categoryItem] = useState(props.categoryItem ? props.categoryItem : new InteriorCategory());

  const [material, setMaterial] = useState(materialItem.material);
  const [interiorType, setInteriorType] = useState(categoryItem.interiorType);
  const [interiorTypeId, setInteriorTypeId] = useState(categoryItem.interiorType_subId);
  const [ornamentType1, setOrnamentType1] = useState(categoryItem.ornamentType1);
  const [ornamentType1Id, setOrnamentType1Id] = useState(categoryItem.ornamentType1_subId);
  const [ornamentType2, setOrnamentType2] = useState(categoryItem.ornamentType2);
  const [ornamentType2Id, setOrnamentType2Id] = useState(categoryItem.ornamentType2_subId);
  const [seatAccent, setSeatAccent] = useState(categoryItem.seatAccent);
  const [armRest, setArmRest] = useState(categoryItem.armRest);
  const [headliner, setHeadliner] = useState(categoryItem.headliner);
  const [ip, setIp] = useState(categoryItem.ip);
  const [carpet, setCarpet] = useState(categoryItem.carpet);
  const [isExtraCost, setIsExtraCost] = useState(categoryItem.isExtraCost);
  const [selectedGroup, setSelectedGroup] = useState('');
  const addMode = !categoryItem.id;
  const {
    checklist,
    setChecklist,
    hasSelection,
    isAllSelected,
    selectAll,
    selectItem,
  } = useChecklist();

  useEffect(() => {
    const groupModelApplicability = !categoryItem.id
      ? interiorColorTypes.groups
      : categoryItem.groups;

    const groupList = groupsWithModelCodeXform(vehicleModels, groupModelApplicability);

    if (Object.keys(groupModelApplicability).length) {
      groupList.forEach(group => {
        groupModelApplicability.forEach(applicability => {
          if (applicability.id === group.id) {
            group.items.forEach(item => {
              Object.entries(applicability.models).forEach(entry => {
                const [key, value] = entry;
                if (item.id === key) {
                  item.selected = value;
                }
              });
            });
            group.selected =
              group.items.length === group.items.filter(modelCode => modelCode.selected).length;
          }
        });
      });
    }

    setChecklist(groupList);
  }, [vehicleModels, setChecklist, interiorColorTypes, categoryItem]);

  const handleOnSelectGroup = useCallback(
    (group: string) => {
      setSelectedGroup(group === selectedGroup ? '' : group);
    },
    [selectedGroup]
  );

  const isValid = () => {
    setIsSubmitted(true);
    return material.value && interiorType.value && hasSelection;
  };

  const submit = () => {
    if (isValid()) {
      const catObj = categoryItem.id ? categoryItem : new InteriorCategory();
      const selection = [] as ChecklistItem[];
      checklist.forEach(interiorGroup => {
        if (!selection.find(item => item.name === interiorGroup.name)) {
          selection.push(interiorGroup);
        }
      });

      catObj.material = material;
      catObj.interiorType = interiorType;
      catObj.interiorType_subId = interiorTypeId.toUpperCase();
      catObj.ornamentType1 = ornamentType1;
      catObj.ornamentType1_subId = ornamentType1Id.toUpperCase();
      catObj.ornamentType2 = ornamentType2;
      catObj.ornamentType2_subId = ornamentType2Id.toUpperCase();
      catObj.seatAccent = seatAccent;
      catObj.armRest = armRest;
      catObj.headliner = headliner;
      catObj.ip = ip;
      catObj.carpet = carpet;
      catObj.isExtraCost = isExtraCost;
      catObj.groups = reassignModelCodes(selection, interiorColorTypes.groups);
      saveInteriorCategory(catObj);
      setIsSubmitted(false);
      closeModal();
    }
  };

  return (
    <>
      <ModalHeader onClose={closeModal}>{addMode ? 'Add' : 'Edit'} Interior Category</ModalHeader>
      {isStep1 && (
        <>
          <ModalBody>
            <div className={styles.dropdownRow}>
              <ColorsInteriorDropdown
                intItem={material}
                type="materials"
                addBtnText="Material"
                list={interiorColorTypes.materials}
                setValue={setMaterial}
                onSave={saveInteriorColorType}
                placeholder="Materials"
                required={isSubmitted}
              />
              <span className={styles.currencyInput}>
                <Input
                  placeholder="MSRP-Optional"
                  value={isExtraCost}
                  onChange={e => setIsExtraCost(e.currentTarget.value.trim())}
                />
              </span>
            </div>
            <div className={styles.dropdownRow}>
              <ColorsInteriorDropdown
                intItem={interiorType}
                type="interiorTypes"
                addBtnText="Interior Type"
                list={interiorColorTypes.interiorTypes}
                setValue={setInteriorType}
                onSave={saveInteriorColorType}
                placeholder="Interior"
                required={isSubmitted}
              />
              <Input
                className={styles.upperCaseStyling}
                placeholder="ID"
                value={interiorTypeId}
                onChange={e => setInteriorTypeId(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.dropdownRow}>
              <ColorsInteriorDropdown
                intItem={ornamentType1}
                type="ornaments"
                addBtnText="Ornament"
                list={interiorColorTypes.ornaments}
                setValue={setOrnamentType1}
                onSave={saveInteriorColorType}
                placeholder="Ornament 1"
              />
              <Input
                className={styles.upperCaseStyling}
                placeholder="ID"
                value={ornamentType1Id}
                onChange={e => setOrnamentType1Id(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.dropdownRow}>
              <ColorsInteriorDropdown
                intItem={ornamentType2}
                type="ornaments"
                addBtnText="Ornament"
                list={interiorColorTypes.ornaments}
                setValue={setOrnamentType2}
                onSave={saveInteriorColorType}
                placeholder="Ornament 2"
              />
              <Input
                className={styles.upperCaseStyling}
                placeholder="ID"
                value={ornamentType2Id}
                onChange={e => setOrnamentType2Id(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.inputRow}>
              <Input
                value={seatAccent}
                placeholder="Seat Accent"
                onChange={e => setSeatAccent(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.inputRow}>
              <Input
                value={armRest}
                placeholder="CC Armrest"
                onChange={e => setArmRest(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.textAreaRow}>
              <TextArea
                noResize
                theme="formControl"
                value={headliner}
                placeholder="Headliner"
                onChange={e => setHeadliner(e.currentTarget.value)}
              />
            </div>
            <div className={styles.inputRow}>
              <Input
                value={ip}
                placeholder="IP"
                onChange={e => setIp(e.currentTarget.value.trim())}
              />
            </div>
            <div className={styles.inputRow}>
              <Input
                value={carpet}
                placeholder="Carpet"
                onChange={e => setCarpet(e.currentTarget.value.trim())}
              />
            </div>
            {categoryItem.rejectNotes && (
              <div>
                <span>Reject Notes</span>
                <p>{categoryItem.rejectNotes}</p>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => closeModal()}>
              Cancel
            </Button>
            {!addMode && (
              <Button variant="primary" onClick={() => deleteInteriorCategory(categoryItem)}>
                Delete
              </Button>
            )}
            <Button variant={addMode ? 'primary' : 'secondary'} onClick={() => setIsStep1(false)}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {!isStep1 && (
        <>
          <ModalBody>
            <FilterTab>
              <FilterTabColumns>
                <FilterTabColumn>
                  <Checkbox
                    id="AllCategories"
                    className={styles.all}
                    checked={isAllSelected}
                    onChange={e => {
                      selectAll(e.currentTarget.checked);
                    }}
                  >
                    <span>All</span>
                  </Checkbox>
                  <FilterTabColumnList>
                    {checklist?.map(item => (
                      <div key={item.name} className={styles.treeMenu}>
                        <div
                          onClick={() => {
                            handleOnSelectGroup(item.name);
                          }}
                          className={cx(styles.down, {
                            [styles.up]: selectedGroup === item.name,
                          })}
                        >
                          <Checkbox
                            id={`chboxGrp${item.name}`}
                            checked={item.selected}
                            onChange={e => {
                              selectItem(item, e.currentTarget.checked);
                            }}
                          >
                            {item.name}
                          </Checkbox>
                        </div>

                        {selectedGroup === item.name &&
                          item.items?.map(code => (
                            <div key={code.name} className={styles.menuItems}>
                              <Checkbox
                                id={`chbox${code.uniqueKey}`}
                                checked={code.selected}
                                onChange={e => {
                                  selectItem(code, e.currentTarget.checked);
                                }}
                              >
                                {code.name}
                              </Checkbox>
                            </div>
                          ))}
                      </div>
                    ))}
                  </FilterTabColumnList>
                </FilterTabColumn>
              </FilterTabColumns>
            </FilterTab>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => setIsStep1(true)}>
              Back
            </Button>
            {!addMode && (
              <Button variant="primary" onClick={() => deleteInteriorCategory(categoryItem)}>
                Delete
              </Button>
            )}
            <Button variant={addMode ? 'primary' : 'secondary'} onClick={submit}>
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default observer(ColorsLexusInteriorModal);
