import cx from 'clsx';
import NotesPopover from '../../../../../../../../../../components/NotesPopover';
import { TableRow } from '../../../../../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { InteriorColorType } from '../../../../../../../../../../models/colors.model';
import {
  InteriorCategory,
  InteriorGroup,
  InteriorGroupItem,
  InteriorMaterial,
} from '../../../../../../../../../../models/colorsLexus.model';
import { IDValueType } from '../../../../../../../../../../models/common.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../../../models/vehicleModel.model';
import React, { useCallback, useState } from 'react';
import { ConfirmModal, Modal } from 'vapi-ui-common';
import ColorsLexusInteriorGroupModal from '../../../../ColorsLexusInteriorGroupModal';
import ColorsLexusInteriorModal from '../../../../ColorsLexusInteriorModal';
import styles from './colorsLexusInteriorGroupRow.module.scss';
import useAccessibleClick from '../../../../../../../../../../hooks/useAccessibleClick';

interface ColorsLexusInteriorGroupRowProps {
  interiorGroups: InteriorGroup[];
  saveInteriorColorType?(
    value: IDValueType<string>,
    interiorColorType: InteriorColorType,
    isNew?: boolean
  ): void;
  readOnly?: boolean;
  vehicleModels?: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  saveInteriorGroup?(interiorGroupItem: InteriorGroupItem, addGroup: boolean): void;
  deleteInteriorGroup?(groupItem: InteriorGroupItem): void;
}

const SpacerCell = () => <td className={styles.spacerCell} />;

export const ColorsLexusInteriorGroupRow = observer(
  ({
    interiorGroups,
    readOnly,
    vehicleModels,
    saveInteriorGroup,
    deleteInteriorGroup,
  }: ColorsLexusInteriorGroupRowProps) => {
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({} as InteriorGroupItem);
    const handleOnSelectGroup = useCallback(
      (interiorGroup: InteriorGroup) => {
        if (readOnly) {
          return;
        }
        setSelectedGroup(interiorGroup.group);
        setOpenGroupModal(true);
      },
      [readOnly]
    );

    return (
      <>
        <tr className={styles.row}>
          {interiorGroups.map((interiorGroup, index) => (
            <React.Fragment key={interiorGroup.group.uid}>
              <InteriorGroupCell interiorGroup={interiorGroup} onSelect={handleOnSelectGroup} />
              {index < interiorGroups.length - 1 && <SpacerCell />}
            </React.Fragment>
          ))}
        </tr>
        <Modal size="sm" open={openGroupModal} onClose={() => setOpenGroupModal(false)}>
          <ColorsLexusInteriorGroupModal
            closeModal={() => setOpenGroupModal(false)}
            saveInteriorGroup={saveInteriorGroup}
            groupItem={selectedGroup}
            readOnly={readOnly}
            vehicleModels={vehicleModels}
            deleteInteriorGroup={deleteInteriorGroup}
          />
        </Modal>
      </>
    );
  }
);

interface InteriorGroupCellProps {
  interiorGroup: InteriorGroup;
  onSelect: (group: InteriorGroup) => void;
}

const InteriorGroupCell = ({ interiorGroup, onSelect }: InteriorGroupCellProps) => {
  const getGroupColSpan = (group: InteriorGroup) => {
    return group.materials.reduce((sum, mat) => sum + mat.categories.length, 0);
  };

  const handleOnSelectGroup = useAccessibleClick(
    useCallback(() => onSelect(interiorGroup), [interiorGroup, onSelect])
  );

  return (
    <td className={cx(styles.cell, styles.groupCell)} colSpan={getGroupColSpan(interiorGroup)}>
      <div {...handleOnSelectGroup}>{interiorGroup.group.name}</div>
    </td>
  );
};

export const ColorsLexusInteriorMaterialRow = observer(
  ({ interiorGroups }: { interiorGroups: InteriorGroup[] }) => {
    return (
      <tr className={styles.row}>
        {interiorGroups.map((group, index) => (
          <React.Fragment key={group.uid}>
            {group.materials.map(mat => (
              <td key={mat.uid} className={styles.cell} colSpan={mat.categories.length}>
                {mat.material.value}
              </td>
            ))}
            {!group.materials.length && <td className={styles.cell} />}
            {index < interiorGroups.length - 1 && <SpacerCell />}
          </React.Fragment>
        ))}
      </tr>
    );
  }
);

export const ColorsLexusInteriorCategoryRow = observer(
  ({
    interiorGroups,
    readOnly,
    showRejectNotes,
    displayValue,
    saveInteriorCategory,
    saveInteriorColorType,
    deleteInteriorCategory,
    onHeightChange,
    vehicleModels,
  }: {
    interiorGroups: InteriorGroup[];
    readOnly?: boolean;
    showRejectNotes?: boolean;
    displayValue(category: InteriorCategory): JSX.Element;
    saveInteriorCategory?(category: InteriorCategory): void;
    saveInteriorColorType?(
      value: IDValueType<string>,
      interiorColorType: InteriorColorType,
      isNew?: boolean
    ): void;
    deleteInteriorCategory?(interiorCategory: InteriorCategory): void;
    onHeightChange(height: number): void;
    vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  }) => {
    return (
      <TableRow className={styles.row} onFillRowHeightChange={onHeightChange}>
        {interiorGroups.map((group, index) => (
          <React.Fragment key={group.uid}>
            {group.materials.map(mat => (
              <React.Fragment key={mat.uid}>
                {mat.categories.map(cat => (
                  <CategoryCell
                    key={cat.uid}
                    material={mat}
                    category={cat}
                    showRejectNotes={showRejectNotes}
                    displayValue={displayValue(cat)}
                    saveInteriorColorType={saveInteriorColorType}
                    saveInteriorCategory={saveInteriorCategory}
                    deleteInteriorCategory={deleteInteriorCategory}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                ))}
              </React.Fragment>
            ))}
            {!group.materials.length && <td className={styles.cell} />}
            {index < interiorGroups.length - 1 && <SpacerCell />}
          </React.Fragment>
        ))}
      </TableRow>
    );
  }
);

const CategoryCell = observer(
  ({
    material,
    category,
    displayValue,
    readOnly,
    showRejectNotes,
    saveInteriorCategory,
    saveInteriorColorType,
    deleteInteriorCategory = () => void 0,
    vehicleModels,
  }: {
    material: InteriorMaterial;
    category: InteriorCategory;
    displayValue: JSX.Element;
    readOnly?: boolean;
    showRejectNotes?: boolean;
    saveInteriorCategory?(category: InteriorCategory): void;
    saveInteriorColorType?(
      value: IDValueType<string>,
      interiorColorType: InteriorColorType,
      isNew?: boolean
    ): void;
    deleteInteriorCategory?(interiorCategory: InteriorCategory): void;
    vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  }) => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
      <>
        <td
          className={styles.cell}
          onClick={() => {
            if (!readOnly) {
              setOpenEditModal(true);
            }
          }}
        >
          {showRejectNotes && category.rejectNotes && (
            <NotesPopover
              popoverClass={styles.rejectNotes}
              notes={category.rejectNotes}
              readOnly
              handleUpdateItem={() => void 0}
              textButtonClassName={styles.notes}
              iconText=""
              icon="purpleCircle"
            />
          )}
          {displayValue}
        </td>
        <Modal size="sm" open={openEditModal} onClose={() => setOpenEditModal(false)}>
          <ColorsLexusInteriorModal
            materialItem={material}
            categoryItem={category}
            closeModal={() => setOpenEditModal(false)}
            saveInteriorColorType={saveInteriorColorType}
            saveInteriorCategory={saveInteriorCategory}
            deleteInteriorCategory={() => {
              setOpenEditModal(false);
              setOpenDeleteModal(true);
            }}
            vehicleModels={vehicleModels}
          />
        </Modal>
        <ConfirmModal
          open={openDeleteModal}
          headerText={`Delete ${category.interiorType.value}`}
          bodyText={`Are you sure you want delete "${category.interiorType.value}"?`}
          onClose={() => setOpenDeleteModal(false)}
          onConfirm={() => deleteInteriorCategory(category)}
          confirmButtonText="Delete"
        />
      </>
    );
  }
);
