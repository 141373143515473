import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, ConfirmModal, Upload } from 'vapi-ui-common';
import { TableRow } from '../../../../../../components/Table';
import { VersionInfo } from '../../../../../../models/spanishDisclaimers.model';
import styles from './spanishDisclaimerRow.module.scss';
import useAccessibleClick from '../../../../../../hooks/useAccessibleClick';

interface SpanishDisclaimerRowProps {
  item: VersionInfo;
  onPublish: (version: number) => void;
  onFileUpload: (file?: File, resultJSON?: object) => void;
  onDownload: (version: number) => void;
  canEdit?: boolean;
}

const SpanishDisclaimerRow = observer(
  ({ item, onPublish, onFileUpload, onDownload, canEdit }: SpanishDisclaimerRowProps) => {
    const [publishDisclaimersModal, setPublishDisclaimersModal] = useState(false);
    const handleDownload = useAccessibleClick(() => onDownload(item.englishVersion));

    return (
      <TableRow zebra>
        <td className={styles.tableText}>
          <span className={styles.content}>Disclaimers</span>
        </td>
        <td className={styles.tableText}>
          <div>EN: V{item.englishVersion}</div>
          <div>ES: V{item.spanishVersion}</div>
        </td>
        <td className={styles.tableText}>
          {canEdit && (
            <Upload onUpload={onFileUpload} uploadLabel="Upload" id="spanish-disclaimers" />
          )}
        </td>
        <td className={styles.tableText}>
          <div {...handleDownload}>
            <span className={styles.actionLabel}>
              Download
              <FontAwesomeIcon className={styles.upDown} icon={faArrowDown} />
            </span>
          </div>
        </td>
        <td className={styles.tableText}>
          {canEdit && (
            <>
              <Button variant="secondary" onClick={() => setPublishDisclaimersModal(true)}>
                Publish
              </Button>

              <ConfirmModal
                headerText="Publish Disclaimers"
                bodyText={`Are you sure you want to publish the Disclaimers version ${item.spanishVersion}?`}
                confirmButtonText={'Publish'}
                open={publishDisclaimersModal}
                onClose={() => setPublishDisclaimersModal(false)}
                onConfirm={() => onPublish(item.spanishVersion)}
              />
            </>
          )}
        </td>
      </TableRow>
    );
  }
);

export default SpanishDisclaimerRow;
