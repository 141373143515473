import React, { useCallback } from 'react';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import styles from './dropdownListSelect.module.scss';
import useComponentVisible from '../../hooks/useComponentVisible';
import useAccessibleClick from '../../hooks/useAccessibleClick';

export interface DropdownListSelectProps {
  list?: Array<string>;
  onSelect?: (item: string) => void;
  children?: JSX.Element;
}

const DropdownListSelect = ({
  list = [],
  onSelect = () => ({}),
  children = <div>click me</div>,
}: DropdownListSelectProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const openDropdown = useCallback(() => {
    setIsComponentVisible(true);
  }, [setIsComponentVisible]);

  const handleOpenDropdown = useAccessibleClick(openDropdown);

  const handleSelect = useCallback(
    (val: string) => {
      onSelect(val);
      setIsComponentVisible(false);
    },
    [setIsComponentVisible, onSelect]
  );

  return (
    <Dropdown>
      <div {...handleOpenDropdown} data-testid="dropdown-list-select-comp">
        {children}
      </div>
      <DropdownContent
        menuClass={styles.dropDownListSelectContainer}
        open={isComponentVisible}
        ref={ref}
      >
        <ul className={styles.dropDownListSelect}>
          {list?.map(item => (
            <DropdownItem key={item} item={item} onSelect={handleSelect} />
          ))}
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

interface DropdownItemProps {
  item: string;
  onSelect: (item: string) => void;
}

const DropdownItem = ({ item, onSelect }: DropdownItemProps) => {
  const handleClick = useAccessibleClick(() => onSelect(item));

  return (
    <li {...handleClick} data-testid="dropdown-list-select-item">
      {item}
    </li>
  );
};

export default DropdownListSelect;
